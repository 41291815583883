import vector from "../assets/eldaLogo.svg";
import vector2 from "../assets/Logo2.svg";
import vector3 from "../assets/BlueLogo.svg"
import { useNavigate, Link, NavLink } from "react-router-dom";
import styles from "../styles/NavBar.module.css";
import Dropdown from 'react-bootstrap/Dropdown';
import NavDropdown from 'react-bootstrap/NavDropdown';
import ClinicalTrials from "./ClinicalTrials";

const NavBar = ({headerMode = ''}) => {
  const navigate = useNavigate();

  const handleClickLogo = () => {
    window.location.href = "/";
  };

  const handleClickLogin = () => {
    navigate("/login");
  };

  const onClick = (pathName: string) => {
      window.location.href = pathName;
  }


  return (
    <div className={`${styles.navbar} ${styles[headerMode]}`}>
        <div className={styles.middleLinks}>
          {/* <a className={`${styles.product} ${styles[headerMode]}`} onClick={() => onClick("/under-construction")}>products</a> */}
          <NavDropdown
              id="nav-dropdown-dark-example"
              title="products"
              menuVariant="dark"
              className={`${styles.product} ${styles[headerMode]}`} 
            >
              <NavDropdown.Item onClick={() => onClick("/trials")}>Clinical research</NavDropdown.Item>
              <NavDropdown.Item onClick={() => onClick("/studies")}>Distributed studies</NavDropdown.Item>
              <NavDropdown.Item onClick={() => onClick("/under-construction")}>Consumer testing</NavDropdown.Item>
            </NavDropdown>
          <a className={`${styles.about} ${styles[headerMode]}`} onClick={() => onClick("/about")}>about</a>          
        </div>
        <div className={styles.loginB}>
            <a className={`${styles.login} ${styles[headerMode]}`} onClick={() => onClick("/under-construction")}>login</a>
        </div>
    </div>
  );
};

export default NavBar;

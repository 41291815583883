import styles from "../styles/WorkUs.module.css"
import blackArrow from "../assets/blackarrow.svg";
import whiteArrow from "../assets/whitearrow.svg";
import eldaLogoRender from "../assets/EldaLogoRender.png"

const WorkUs = () => {
    return(
        <div className={styles.mainContainer}>
            <div className={styles.graphic}>
                <img className={styles.logoRender} src={eldaLogoRender} />
            </div>
            <div className={styles.mainText}>
                <h1>Build AI that works for good</h1>
                <p>Build products that make research easier, multiplying your impact.</p>
                <div className={styles.mainButtons}>
                    <button id={styles.people}>types of people we want <img src={whiteArrow} alt="arrow" width="12px" height="10px"/></button>
                    <a href="mailto:cam@elda.bio">
                        <button id={styles.email}>shoot us an email <img src={blackArrow} alt="arrow" width="12px" height="10px"/></button>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default WorkUs;
import logo from "../assets/eldalogo.png";
import { useNavigate, Link } from "react-router-dom";
import styles from "../styles/Footer.module.css";
import blueArrow from "../assets/bluearrow.svg";
import { useState } from "react";

const Footer = ({headerMode = ''}) => {

    const onClick = (pathName: string) => {
        window.location.href = pathName;
    }

    const [email, setEmail] = useState("");

    const submit = (e: React.ChangeEvent<any>) => {
        e.preventDefault();
        fetch('https://hooks.zapier.com/hooks/catch/17264339/3fswvc4/', {
            method: "POST",
            body: JSON.stringify({email})
        })
    }

    console.log(email);

  return (
    <div className={styles.footerContainer}>
        <div className={`${styles.footer} ${styles[headerMode]}`}>
            <div className={styles.footerMain}>
                <div className={`${styles.mainCta} ${styles[headerMode]}`}>
                    <h1>elda</h1>
                        <form className={styles.emailBox} 
                            action="https://hooks.zapier.com/hooks/catch/17264339/3fswvc4/"
                            method="post"
                        >
                                <input className={styles.formInput} placeholder="join our email list today" type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                                <button className={styles.formInput} type="submit"><img src={blueArrow} width="16px"/></button>
                        </form>
                </div>
            </div>
            <div className={styles.footerLinks}>
                <div className={styles.linkContainer}>
                    <a id={styles.link} onClick={() => onClick("/about")}>about</a>
                    <a id={styles.link} onClick={() => onClick("/under-construction")}>copyright 2023 elda bio</a>          
                    <a id={styles.link} onClick={() => onClick("/under-construction")}>privacy policy</a>          
                    <a id={styles.link} onClick={() => onClick("/under-construction")}>contact us</a>          
                    <a id={styles.link} onClick={() => onClick("/work")}>work with us</a>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Footer;

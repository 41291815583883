import styles from "../styles/Home.module.css";
import whiteArrow from "../assets/whitearrow.svg";
import blackArrow from "../assets/blackarrow.svg";
import blueArrow from "../assets/bluearrow.svg";
import lightning from "../assets/Lightning.png";
import integration from "../assets/Integration.png";
import commerce from "../assets/ecommerceicon.svg";
import telehealth from "../assets/telehealthicon.svg";
import upscaling from "../assets/Upscaling.png";
import teleScreen from "../assets/teleScreen.png";
import clinicaltrialicon from "../assets/clinicaltrialicon.svg";
import imagingIcon from "../assets/ImageIcon.png"
import { useEffect } from "react";

const Home = () => {

    return(
        <div>
            <div className={styles.mainPage}>
                <div className={styles.mainText}>
                    <h1>Supercharge your research in moments.</h1>
                    <div className={styles.description}>
                        <p>End-to-end participant management, at your fingertips.</p>
                        <p>Build, test, and scale at lightning speed.</p>
                    </div>
                    <div className={styles.mainButtons}>
                        <a href="https://calendly.com/eldateam/discover">
                            <button>schedule meeting today <img src={blackArrow} width="10px" height="10px"></img></button>
                        </a>
                        {/*<button id={styles.derm}>i'm a sponsor <img src={whiteArrow} width="12px" height="10px"></img></button> */}
                    </div>
                </div>
                <div className={styles.icon}>
                    {/* <h1>Graphic</h1> */}
                </div>
            </div>
            <div className={styles.tooling}>
                <h2>Infrastructure for research and beyond.</h2>
            </div>
            <div className={styles.mid}>
                <div className={styles.midFormat}>
                    <div className={styles.leveraging}>
                        <div className={styles.leverage}>
                            <p id={styles.flexible}>HYPER FLEXIBILITY</p>
                            <h2>Leverage artifical intelligence</h2>
                            <p>Unlock the power of AI to help your users take better photos.</p>
                        </div>
                    </div>
                    <div className={styles.features}>
                        <div className={styles.guidance}>
                            <img id={styles.upscImg} src={upscaling}></img>
                            <h3>Patient engagement tools</h3>
                            <p>Analyze your patient database faster than ever before with AI, improving data quality and gathering.</p>
                            <a href="https://calendly.com/eldateam/discover">
                                <button>discover engagement <img src={blackArrow} width="10px" height="10px"></img></button>
                            </a>
                        </div>
                        <div className={styles.upscale}>
                            <img id={styles.upscImg} src={imagingIcon}></img>
                            <h3>Image guidance</h3>
                            <p>Use our fine-tuned AI models to deliver clinical quality imaging from anywhere in select verticals including dermatology.</p>
                            <a href="https://calendly.com/eldateam/discover">
                                <button>explore upscaling <img src={blackArrow} width="10px" height="10px"></img></button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className={styles.levGraphic}>
                    {/* <h1>Graphic</h1> */}
                </div>
            </div>
            <div className={styles.applications}>
                <div className={styles.telehealth}>
                    <div className={styles.teleFormat}>
                        <div className={styles.teleHeader}>
                            <img id={styles.clinicalTrialImg} src={clinicaltrialicon}/>
                            <h2>Clinical research</h2>
                            <h5>Supercharge your clinical research and trials with Elda.</h5>
                        </div>
                        <div className={styles.teleBottom}>
                            <ul>
                                <li><input type="checkbox" checked={true} disabled={true} readOnly={true}/> <span>Increase patient adherence and ensure retention</span></li>
                                <li><input type="checkbox"checked={true} disabled={true} readOnly={true} /> <span>Onboard patients with ease using our direct platform</span></li>
                                <li><input type="checkbox"checked={true} disabled={true} readOnly={true} /> <span>HIPAA-compliant data transfer</span></li>
                            </ul>
                            <a href="https://calendly.com/eldateam/discover">
                                <button>enquire today <img src={whiteArrow} width="12px" height="12px"></img></button>
                            </a>
                        </div> 
                    </div>
                    <div className={styles.teleImage}>
                        <img className={styles.clinicalResearchImg} src={teleScreen}></img>
                    </div>
                </div>
                <div className={styles.secondary}>
                    <div className={styles.commerce}>
                        <div className={styles.commerceHeader}>
                            <img src={telehealth}></img>
                            <a href="https://calendly.com/eldateam/discover">
                                <button>schedule call <img src={whiteArrow} width="12px" height="12px"></img></button>
                            </a>
                        </div>
                        <div className={styles.commerceFormat}>
                            <div className={styles.commerceMid}>
                                <h2>Distributed studies</h2>
                                <h6>Conduct research studies on Elda across any topic.</h6>
                            </div> 
                            <div className={styles.commerceFooter}>
                                <ul>
                                    <li><input type="checkbox" checked={true} disabled={true} readOnly={true}/> <span>Directly survey participants using our mobile app</span></li>
                                    <li><input type="checkbox"checked={true} disabled={true} readOnly={true} /> <span>HIPAA-compliant data transfer</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={styles.trials}>
                        <div className={styles.trialsHeader}>
                            <img src={commerce}/>
                            <a href="https://calendly.com/eldateam/discover">
                                <button>schedule call <img src={blackArrow} width="12px" height="12px"></img></button>
                            </a>
                        </div>
                        <div className={styles.trialsFormat}>
                            <h2>Consumer testing</h2>
                            <h6>Use Elda to manage decentralized consumer product testing.</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.integrationContainer}>
                <div className={styles.integration}>
                    <div className={styles.intFormat}>
                        <p id={styles.ludicrous}>LUDICROUS MODE</p>
                        <h1>Launch Elda in moments</h1>
                        <p id={styles.integrateDesc}>As researchers, we understand the necessity to move fast. Elda takes you from study design to launch in hours, not months, helping you collect data simpler from day 0.</p>
                        <a href="https://calendly.com/eldateam/discover">
                            <button id={styles.schedule}>schedule a call <img src={blueArrow} width="10px" height="10px"></img></button>
                        </a>
                    </div>
                </div>
                <div className={styles.moments}>
                    <div className={styles.momFormat}>
                        <div className={styles.fastapi}>
                            <img src={lightning} width={'64px'}></img>
                            <h3>Absurdly fast app</h3>
                        </div>
                        <div className={styles.seamless}>
                            <img src={integration} width={'64px'}></img>
                            <h3>Seamless integration</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;
import styles from "../styles/Construction.module.css"
import blueArrow from "../assets/bluearrow.svg";
import whiteArrow from "../assets/whitearrow.svg";

const Construction = () => {
    return(
        <div className={styles.mainContainer}>
            <div className={styles.heroContainer}>
                <h1>We're working on this!</h1>
                <div className={styles.subHeroText}>
                    <p>We just launched our new site, so not everything is done yet.</p>
                    <p>But get ready, because big things are up ahead.</p>
                </div>
                <div className={styles.buttonContainer}>
                    <div className={styles.emailButton}>
                        <a href="mailto:hello@elda.bio">
                            <button>email us <img src={blueArrow}/></button>
                        </a>
                    </div>
                    <div className={styles.scheduleButton}>
                        <a href="https://calendly.com/eldateam/discover-elda">
                            <button>schedule call<img src={whiteArrow}/></button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Construction;
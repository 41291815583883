import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import NavDropdown from 'react-bootstrap/NavDropdown';
import menuIcon from '../assets/menuIcon.svg';
import blueMenu from '../assets/blueMenu.svg';
import blackMenu from '../assets/blackMenu.svg';
import logo from '../assets/eldaLogo.svg';
import logo2 from '../assets/Logo2.svg';
import logo3 from '../assets/BlueLogo.svg';
import styles from "../styles/NavDrop.module.css";

const NavDrop = ({headerMode = ''}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onClick = (pathName: string) => {
    window.location.href = pathName;
  }

  return (
    <div className={styles.nav}>
      <div className={styles.logo}>
        <a onClick={() => onClick("/")}>
          {headerMode === 'light' ? <img src={logo2} width="80px"/> : (headerMode === 'blue' ? <img src={logo3} width="100px"/> : <img src={logo} width="80px"/>)}
        </a>
      </div>
      <div>
        <Button variant="link" onClick={handleShow}>
          {headerMode === 'light' ? <img src={blackMenu} width="25px" height="25px"/> : (headerMode === 'blue' ? <img src={blueMenu} width="25px" height="25px"/> 
          : <img src={menuIcon} width="25px" height="25px"/>)}
        </Button>
        <Offcanvas show={show} onHide={handleClose} placement="end" offcanvas-width="100vw">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Menu</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className={styles.menu}>
            <a onClick={() => onClick("/")}>home</a> 
            <a onClick={() => onClick("/about")}>about</a> 
            <NavDropdown
                id="nav-dropdown-dark-example"
                title="products"
                menuVariant="dark"
                // className={`${styles.product} ${styles[headerMode]}`} 
              >
                <NavDropdown.Item onClick={() => onClick("/trials")}>Clinical Trials</NavDropdown.Item>
                <NavDropdown.Item onClick={() => onClick("/under-construction")}>Teleheatlh</NavDropdown.Item>
                <NavDropdown.Item onClick={() => onClick("/commerce")}>E-commerce</NavDropdown.Item>
              </NavDropdown>
            <a onClick={() => onClick("/under-construction")}>login</a>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </div>
  );
}

export default NavDrop;
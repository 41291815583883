import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home"; 
import NavBar from "./components/Navbar"; 
import NavDrop from "./components/NavDrop";
import Product from "./components/Product";
import About from "./components/About"; 
import Login from "./components/Login"; 
import Footer from "./components/Footer";
import ContactUs from "./components/ContactUs";
import Copyright from "./components/Copyright";
import Privacy from "./components/Privacy";
import WorkUs from "./components/WorkUs";
import Construction from "./components/Construction";
import ClinicalTrials from "./components/ClinicalTrials";
import Studies from "./components/Studies";
import {useState} from "react";
import backgroundHome from './assets/gradient-long-new.svg'
import { useLocation } from "react-router-dom";

function App() {
  const path = window.location.pathname;
  const fixedPath = path.replace('/', 'slash ')

  let navbarTypeDict: Record<string, string> = {
    "slash ": "dark",
    "slash under-construction": "light",
    "slash product": "light",
    "slash about": "light",
    "slash contact": "light",
    "slash privacy": "light",
    "slash work": "blue",
    "slash login": "login",
    "slash trials": "light",
    "slash studies": "light",
  }

  const onClick = () => {
    console.log("Clicked")
  }

  const theme = navbarTypeDict[fixedPath];
  var isMobile = Math.min(window.screen.width, window.screen.height) < 768 || navigator.userAgent.indexOf("Mobi") > -1;
  console.log(isMobile);

  return (
    <Router>
      <div className={"App " + theme}>
        {isMobile ? (<NavDrop headerMode={theme}/>) : (<NavBar headerMode={theme}/>)}
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/product" element={<Product/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/login" element={<Login/>} />
          <Route path="/contact" element={<ContactUs/>} />
          <Route path="/copyright" element={<Copyright/>} />
          <Route path="/privacy" element={<Privacy/>} />
          <Route path="/work" element={<WorkUs/>} />
          <Route path="/under-construction" element={<Construction/>} />
          <Route path="/trials" element={<ClinicalTrials/>} />
          <Route path="/studies" element={<Studies/>} />
        </Routes>
        <Footer headerMode={theme} />
      </div>
    </Router>
  );
}

export default App;

import styles from "../styles/ClinicalTrials.module.css"
import blueArrow from "../assets/bluearrow.svg";
import whiteArrow from "../assets/whitearrow.svg";

const ClinicalTrials = () => {
    return(
        <div className={styles.mainContainer}>
            <h1>Research better with Elda</h1>
            <p>Manage patients at scale with clinical-quality imaging, remote notification, and other things.</p>
            <div className={styles.mainButtons}>
                <a href="mailto:hello@elda.bio">
                    <button id={styles.email}>email us <img src={blueArrow} alt="arrow" width="12px" height="10px"/></button>
                </a>
                <a href="https://calendly.com/eldateam/discover-elda">
                    <button id={styles.call}>schedule call <img src={whiteArrow} alt="arrow" width="12px" height="10px"/></button>
                </a>
            </div>
        </div>
    )
}

export default ClinicalTrials;
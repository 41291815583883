import styles from "../styles/About.module.css"
import blueArrow from "../assets/bluearrow.svg";

const About = () => {
    return(
        <div className={styles.mainContainer}>
            <div className={styles.header}>
                <h1>We build together<span className={styles.period}>.</span></h1>
            </div>
            <div className={styles.middle}>
                <h2>We're Elda, and we're building AI to change the world of research.</h2>
            </div>
            <div className={styles.bottom}>
                <p id={styles.smallText}>BUILD COOL THINGS</p>
                <h3>Join our team</h3>
                <p>If you care a lot about having real impact, you might be a good fit for the Elda team. Whether you’re a high school student (our founding team is under 19!) or an industry veteran, we’d love to talk to you.</p>
                <a href="mailto:cam@elda.bio">
                    <button>shoot us an email <img src={blueArrow} alt="arrow" width="12px" height="10px"/></button>
                </a>
            </div>
        </div>
    )
}

export default About;